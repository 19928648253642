const trans = {
  'accessDeniedPage.header': 'Du saknar tyvärr behörighet till denna sida',
  'accessDeniedPage.helpMessage': 'Vänligen kontrollera länken',
  'en': 'English',
  'errors.generalAuthPNRError': 'Detta personnummer kan inte användas för autentisering',
  'errors.generalAuthTokenError': 'Länken kan inte användas för autentisering',
  'errors.invalidOrNoToken': 'Länken är inte aktuell eller otillräcklig',
  'errors.invalidPNRError': 'Felaktigt personnummer eller felaktigt format',
  'errors.noCredsToSaveError': 'Otillräckliga eller ej korrekta uppgifter',
  'errors.noPatientError': 'Ingen information funnen',
  'errors.noPNRError': 'Inget personnummer inskrivet',
  'errors.noUserWithPNR': 'Ingen information hittas för detta personnummer',
  'errors.someDataIsNotValidError': 'Ej korrekta uppgifter',
  'errors.tokenWasAlreadyAnswered': 'Det finns redan ett svar för denna länk',
  'errors.unexpectedError': 'Ett oväntat fel inträffade',
  'errors.unknownError': 'Ett oväntat fel inträffade',
  'firstName': 'Ditt förnamn',
  'form.error.emailFormat': 'Vänligen kontrollera e-post',
  'form.error.mustNotEmpty': 'Fältet kan inte vara tomt',
  'form.error.persNumberFormat': 'Vänligen kontrollera personnumret',
  'form.hint.fieldIsOptional': 'Valfritt',
  'form.hint.mustNotEmpty': 'Obligatorisk',
  'form.placeholder.select': 'Välj ditt svar...',
  'form.placeholder.typeHere': 'Skriv här...',
  'lastName': 'Ditt efternamn',
  'loader.retrievingRecords': 'Kontrollerar anslutning...',
  'notFoundPage.header': 'Det verkar som att sidan inte finns',
  'notFoundPage.helpMessage': 'Vänligen kontrollera länken',
  'patient.search.personalNumberHeading': 'Sök via personnummer',
  'patientEmail': 'Patientens e-post (endast om personnummer saknas)',
  'patientSearch.hint.emailFormat': 'Vänligen säkerställ korrekt format',
  'patientSearch.hint.persNumberFormat': '12 tecken',
  'patientSearch.provideEmailOrPnr': 'Vänligen skriv in personens personnummer eller e-post',
  'personalNumber': 'Patientens personnummer',
  'phone': 'Ditt telefonnummer',
  'relative.relativeForm.heading': 'Berätta om dig själv',
  'relative.relativeForm.headingIfAuth': 'Symtomenkät för',
  'relative.relativeForm.headingIfAuthDescriptionAdditional': 'Personen i fråga kan av olika skäl ha svårt att lämna uppriktiga och/eller utförliga beskrivningar av sitt tillstånd, varför en närståendes perspektiv är av stort värde i bedömningen.',
  'relative.relativeForm.headingIfAuthDescriptionMain1': 'Du har blivit tillfrågad av',
  'relative.relativeForm.headingIfAuthDescriptionMain2': 'att svara på en Symtomenkät i samband med dennes kognitiva bedömning som görs genom appen Minnesmottagningen.',
  'relative.relativeForm.headingSearch': 'Vänligen fyll i nedan för att starta Symtomenkäten',
  'relative.relativeForm.saveButton': 'Starta',
  'startPage.button.getStarted': 'Starta',
  'startPage.button.goToSearch': 'Gå till sök',
  'startPage.heading.linkNotValid': 'Tyvärr är den angivna länken inte längre aktiv',
  'startPage.heading.noQuestionsFromServer': 'Ett fel inträffade',
  'startPage.heading.noQuestionsTip1': 'Försök att ladda om sidan',
  'startPage.heading.noQuestionsTip2': 'Försök att slutföra med korrekt personnummer',
  'startPage.heading.searchOption': 'Försök att slutföra med korrekt personnummer',
  'startPage.heading.welcome': 'Välkommen till symtomenkäten',
  'startPage.welcomeMessage1': 'Vänligen svara på frågor gällande',
  'successPage.heading': 'Svaren är nu sparade.',
  'successPage.thanksMessage': 'Tack för din återkoppling. Informationen är av stort värde för den fortsatta utredningen.',
  'survey.buttons.back': 'Tillbaka',
  'survey.buttons.next': 'Nästa',
  'survey.buttons.save': 'Skicka',
  'sv': 'Svenska',
  'thePatient': 'patienten',
  'thePatientCapitalized': 'Patienten',
  'word.behaviour': 'beteende',
  'word.of': 'av',
  'word.pleaseWait': 'Vänligen vänta',
  'word.section': 'Sektion',
};
 export default trans;