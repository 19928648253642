const trans = {
  'accessDeniedPage.header': 'It seems you are not supposed to be here',
  'accessDeniedPage.helpMessage': 'Please check the link you\'ve just visited to',
  'en': 'English',
  'errors.generalAuthPNRError': 'Provided personal number cannot be used for authentication',
  'errors.generalAuthTokenError': 'Provided link cannot be used for authentication',
  'errors.invalidOrNoToken': 'Provided link is not valid or doesn\'t contain required data',
  'errors.invalidPNRError': 'Invalid personal number provided',
  'errors.noCredsToSaveError': 'Provided credentials are not valid or don\'t contain required data',
  'errors.noPatientError': 'There is no patient data fetched',
  'errors.noPNRError': 'No personal number provided',
  'errors.noUserWithPNR': 'There is no patient with provided personal number',
  'errors.someDataIsNotValidError': 'Some of the provided data is not valid',
  'errors.tokenWasAlreadyAnswered': 'It seems the survey was already answered by this link',
  'errors.unexpectedError': 'An unexpected error occurred',
  'errors.unknownError': 'An unknown error occurred',
  'firstName': 'Your first name',
  'form.error.emailFormat': 'Please check format of email',
  'form.error.mustNotEmpty': 'Field must not be empty',
  'form.error.persNumberFormat': 'Please check length and format of personal number',
  'form.hint.fieldIsOptional': 'Optional',
  'form.hint.mustNotEmpty': 'Required',
  'form.placeholder.select': 'Select your answer...',
  'form.placeholder.typeHere': 'Type here...',
  'lastName': 'Your last name',
  'loader.retrievingRecords': 'Retrieving records and checking data...',
  'notFoundPage.header': 'It seems the page you requested does not exist',
  'notFoundPage.helpMessage': 'Please check the link you\'ve just visited to',
  'patient.search.personalNumberHeading': 'Search by patient\'s personal number',
  'patientEmail': 'Patient\'s e-mail (only of Swedish personal number is missing)',
  'patientSearch.hint.emailFormat': 'Provide email with correct format',
  'patientSearch.hint.persNumberFormat': '12 characters',
  'patientSearch.provideEmailOrPnr': 'Please provide person\'s personal number or email',
  'personalNumber': 'Patient\'s personal number',
  'phone': 'Your phone number',
  'relative.relativeForm.heading': 'Please tell us about yourself',
  'relative.relativeForm.headingIfAuth': 'Symptom Survey for',
  'relative.relativeForm.headingIfAuthDescriptionAdditional': 'The person being investigated may due to different reasons experience difficulties in providing a correct and complete description of the condition and symptoms. Therefore, the relatives\' perspective is of great value in the assessment.',
  'relative.relativeForm.headingIfAuthDescriptionMain1': 'You have been asked to complete a Symptom Survey for',
  'relative.relativeForm.headingIfAuthDescriptionMain2': 'as part of a cognitive assessment done through The Memory Clinic app.',
  'relative.relativeForm.headingSearch': 'Please, provide data to start the survey',
  'relative.relativeForm.saveButton': 'Proceed',
  'startPage.button.getStarted': 'Get started',
  'startPage.button.goToSearch': 'Go to search',
  'startPage.heading.linkNotValid': 'It seems the link you provided is not valid',
  'startPage.heading.noQuestionsFromServer': 'An error occurred while fetching data for survey',
  'startPage.heading.noQuestionsTip1': 'Try to refresh the page',
  'startPage.heading.noQuestionsTip2': 'You can also retry to complete the survey with patient\'s personal number',
  'startPage.heading.searchOption': 'You can also complete the survey with patient\'s personal number',
  'startPage.heading.welcome': 'Welcome to Symptom Survey Page',
  'startPage.welcomeMessage1': 'Please answer questions about',
  'successPage.heading': 'Survey results have been saved successfully!',
  'successPage.thanksMessage': 'Thank you for your answers!',
  'survey.buttons.back': 'Back',
  'survey.buttons.next': 'Next',
  'survey.buttons.save': 'Send results',
  'sv': 'Svenska',
  'thePatient': 'the patient',
  'thePatientCapitalized': 'The patient',
  'word.behaviour': 'behaviour',
  'word.of': 'of',
  'word.pleaseWait': 'Please wait',
  'word.section': 'Section',
};
 export default trans;